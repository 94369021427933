import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { UserService } from '../../routes/auth/services/user.service';

export interface ConfirmModel {
  user: any,
  location: any
}

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.css']
})
export class CommentDialogComponent extends DialogComponent<ConfirmModel , any> implements ConfirmModel , OnInit{

  user: any;
  location: any
  public draftTopComment: any = {};
  public isSubmittingComment: boolean = false;

  constructor(dialogService: DialogService,private userSvc: UserService) { 
    super(dialogService);
  }

  ngOnInit() { }

  topCommentClick(e: Event) {

    e.preventDefault();
    if (!this.draftTopComment.text) {
        this.draftTopComment.required = true;
        return;
    }
    this.draftTopComment.required = false;
    this.isSubmittingComment = true;
    let url = 'api/locations/'+this.location._id+'/comment';
   
    let payload = {
        api_token: this.user.api_token,
        comment: this.draftTopComment.text
    };
    
    this.userSvc.commonPost(url, payload).subscribe(res => {
      this.isSubmittingComment = false;
      this.draftTopComment.text = "";
      this.result = res;
      this.closemodal();
      // $('body').removeClass('modal-open');
      // $('.modal-backdrop').remove();
      // this.close();
      // if (res.comment) {
      //   //  this.comments.push(res.comment);
      // }
    }, err => {
      this.isSubmittingComment = false;
    });
  }

  closemodal() {
    this.close();
    // $('body').removeClass('modal-open');
    // $('.modal-backdrop').remove();
    // $('body').css({'padding-right': 'initial'});
  }



}
