import { LayoutComponent } from '../layout/layout.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { LoginGuard } from '../shared/guards/login.guard'
import { AuthGuard } from '../shared/guards/auth.guard';
import { ThankyouComponent } from './thankyou/thankyou.component';
 
export const routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', loadChildren: './home/home.module#HomeModule' },
      { path: 'quick-vote', loadChildren: './quick-vote/quick-vote.module#QuickVoteModule' },
      { path: 'add-location', loadChildren: './add-location/add-location.module#AddLocationModule' },
      { path: 'auth', loadChildren: './auth/auth.module#AuthModule', canActivate: [ LoginGuard ] },
      { path: 'instagram-auth-response', loadChildren: './instagram-auth-response/instagram-auth-response.module#InstagramAuthResponseModule' },
      { path: 'quick-vote-thank-you', component: ThankyouComponent, canActivate: [ AuthGuard ] },
      { path: 'map', loadChildren: './map-view/map-view.module#MapViewModule' },

      { path: '',
        component: FooterComponent,
        children: [
          { path: 'UltimateJourneySA-competition', loadChildren: './urt-competition/urt-competition.module#UrtCompetitionModule' },
          { path: 'locations', loadChildren: './location/location.module#LocationModule' },
          { path: 'my-locations', loadChildren: './my-added-location/my-added-location.module#MyAddedLocationModule', canActivate: [ AuthGuard ] },
          { path: 'my-voted-locations', loadChildren: './my-voted-location/my-voted-location.module#MyVotedLocationModule', canActivate: [ AuthGuard ] },
          { path: 'location/:slug', loadChildren: './detail-location/detail-location.module#DetailLocationModule' },
          { path: 'aboutMH', loadChildren: './about-mh/about-mh.module#AboutMHModule' }, 
        ]
      },
      { path: '**', loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule' },
    ] 
  }
]
       
    
      
     
     
 

   