import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './component/loading/loading.component';
import { ErrorComponent } from './component/error/error.component'
import { KeysPipe } from './pipes/keys.pipe';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';

@NgModule({
  declarations: [LoadingComponent, ErrorComponent, KeysPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BootstrapModalModule.forRoot({container:document.body})
  ],
  exports: [ 
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
    ErrorComponent,
    KeysPipe
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    }
  }
}
