import { Injectable } from '@angular/core';
import { Observable , of  } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http : HttpClient) { }

  get(url: string, httpParams = new HttpParams(), headers = new HttpHeaders()): Observable<any>{
    return this.http.get(url, {params: httpParams, headers: headers}).pipe(
    catchError(err => this.handleErrors(err)));
  }

  post(url: string, payload: Object = {}, headers = new HttpHeaders()): Observable<any>{
    return this.http.post(url,payload, {headers: headers}).pipe(
    catchError(err => this.handleErrors(err)));
  }

  patch(url: string, payload: Object = {}): Observable<any>{
    return this.http.patch(url,payload).pipe(
    catchError(err => this.handleErrors(err)));
  }

  delete(url: string): Observable<any>{
    return this.http.delete(url).pipe(
    catchError(err => this.handleErrors(err)));
  }

  private handleErrors(err: Response): Observable<any> {
    switch (err.status) {
      case 400:
          // Bad Request
          this.presentToast(err['error'].error.message);
          return of(err['error'].error.message);

      case 401:
          // Unauthorized Access
          this.presentToast(err['error'].error.message);
          return of(err['error'].error.message);

      case 403:
          // Forbidden
          this.presentToast(err['error'].error.message);
          return of(err['error'].error.message);

      case 404:
          // Resource Not Found
          this.presentToast(err['error'].error.message);
          return of(err['error'].error.message);
          // return err['error'].error;

      case 422:
          // Resource Not Found
          Object.keys(err['error'].errors).forEach(key => {
              this.presentToast(err['error'].errors[key][0]);
          });
          return of(err['error'].error.message);
          // break;

      case 429:
          // Too Many Requests
          break;

      case 500:
          // Internal Server Error
          this.presentToast(err['error'].error.message);
          return of(err['error'].error.message);
    }

    return of(err);
  }

  private async presentToast(message) {
    alert(message);
  }
}
