
export const environment = {
  production : false,

  APP_URL : 'https://competition-front.mavendev.co.za/',
  API_URL : 'https://competition-admin.mavendev.co.za/',

  GOOGLE_KEY: 'AIzaSyBk31A7467-xKGm-MB8LQaouCPZRONF1wA',

  INSTAGRAM_CLIENT_ID : '8e81305f42c440ccbc496400e3d284db',
  INSTAGRAM_CLIENT_SECRET : '235b78eced49465c8b5a2f88aa0240f0',
  INSTAGRAM_REDIRECT_URI : 'instagram-auth-response',

  FACEBOOK_APP_ID : '415512409283413',

  ALGOLIA_APP_ID : 'VPSHNFS2YN',
  ALGOLIA_SEARCH_ONLY_API_KEY : '9198f152b6106e2bb398296726c042db',
  
};

