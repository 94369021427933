import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { environment } from '../../../../environments/environment';

const apiUrl = environment.API_URL;

@Injectable({
  providedIn: 'root'
})

export class UserService {

  public categories: any = [];

  constructor(private httpSvc : HttpService) { }

  getAllCategory() {
    return this.categories;
  }

  getCountryAndCategory() {
    return this.httpSvc.get(apiUrl + 'api/countries');
  }

  checkLocationExist(placeId: any) {
    return this.httpSvc.get(apiUrl + 'api/locations/' + placeId + '?by=google_place_id');
  }

  commonPost(url: any, payload: any) {
    return this.httpSvc.post(apiUrl + url , payload);
  }

  commonGet(url: any) {
    return this.httpSvc.get(apiUrl + url);
  }

  getUser(token: any) {
    return this.httpSvc.get(apiUrl + 'api/user?api_token=' + token );
  }

  addLocation(payload: any) {    
    return this.httpSvc.post(apiUrl + 'api/locations', payload);
  }

  addMedia(payload: any) {
    return this.httpSvc.post(apiUrl + 'api/locations/media', payload);
  }

  updateUserInfo(params: any) {
    return this.httpSvc.get(apiUrl + 'api/user', params);
  }

  getLocationPerPage() {
    return this.httpSvc.get(apiUrl + 'api/locations?per_page=20&order_by=created_at&order_by_type=desc');
  }
  
}
