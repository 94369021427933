import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { routes } from './routes';
import { ThankyouComponent } from './thankyou/thankyou.component';

@NgModule({
  declarations: [ThankyouComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forRoot(routes)
  ],
  exports:[RouterModule]
})

export class RoutesModule { }
