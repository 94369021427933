import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getData(key):any {
    return window.localStorage.getItem(key);
  }

  saveData(key: string, value: any):void {
    window.localStorage.setItem(key, value);
  }

  removeData(key) {
    window.localStorage.removeItem(key);
  }

  clearStorage() {
    window.localStorage.clear();
  }

}
