import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === null || value === undefined) {
      return [];
    }

    return Object.keys(value);
  }

}
