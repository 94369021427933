import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    CommentDialogComponent
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    CommentDialogComponent
  ],
  entryComponents: [
    CommentDialogComponent
  ]
})

export class LayoutModule { }
