import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor (private router: Router, private storageSvc: StorageService) { }

  canActivate(): boolean {

		if (this.storageSvc.getData('Log_User')) {
			return true;
		} else {
			this.router.navigate(['/']);
			return false;
		}
	}
}