import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { StorageService } from '../../shared/services/storage.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLoggedin: boolean = false;
  public activemenu: any = 'inactive';  
  user: any;

  constructor(
    private router: Router, 
    private authSvc: AuthenticationService, 
    private storageSvc: StorageService ) { }

  ngOnInit() {
    this.authSvc.isLoggedin.subscribe(isLoggedin => {
      this.isLoggedin = isLoggedin;
    });
    
    this.authSvc.currentUser.subscribe(userDetail => {
      if(!userDetail) {
        return;
      }
      this.user = userDetail;
    });
  }
  
  toggleMenu(menu: string) {
    if(this.activemenu === 'active') {
      this.activemenu = 'inactive';
    } else {
      this.activemenu = 'active';
    }
  }

  toggleMenuClose(){
    this.activemenu = 'inactive';
  }

  logout() {
    this.authSvc.logout();
    this.router.navigate(['/']);
  }

}
