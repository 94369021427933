import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { StorageService } from '../services/storage.service';
import { UserService } from '../../routes/auth/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public isLoginSubject = new ReplaySubject<boolean>(0);
  public isLoggedin = this.isLoginSubject.asObservable();

	private currentUserSubject = new BehaviorSubject<any>(null);
	public currentUser = this.currentUserSubject.asObservable();

  constructor(private storageSvc: StorageService, private userSvc: UserService) { 
    this.initializeSession();
  }

  initializeSession() {
    let user = JSON.parse(this.storageSvc.getData('Log_User'));
    if(!user){
      return;
    }

    this.userSvc.getUser(user.api_token).subscribe(resp => {
      this.storageSvc.saveData('Log_User', JSON.stringify(resp.user));
      this.currentUserSubject.next(resp.user);
    });
    this.setAuthentication();
  }

  setAuthentication() {
    this.isLoginSubject.next(true);
  }

  setCurrentUser(user){
    this.currentUserSubject.next(user);
    this.storageSvc.saveData('Log_User', JSON.stringify(user));
  }
  

  logout() {
    this.currentUserSubject.next(null);
    this.storageSvc.clearStorage();
    this.isLoginSubject.next(false);
  }
}
