import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(public router: Router, private storageSvc: StorageService) { }

  canActivate(): boolean {
		if (this.storageSvc.getData('Log_User')){
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
  
}
